import { Country } from '../types'

export default {
  COMMERCE_LAYER_HOST: process.env.GATSBY_COMMERCE_LAYER_HOST || '',
  COMMERCE_LAYER_AUTH_HOST: process.env.GATSBY_COMMERCE_LAYER_AUTH_HOST || '',
  COMMERCE_LAYER_CLIENT_ID: process.env.GATSBY_COMMERCE_LAYER_CLIENT_ID || '',
  EMBONOR_DISTRIBUTION_CENTER_SERVICE_HOST: process.env.GATSBY_EMBONOR_DISTRIBUTION_CENTER_SERVICE_HOST || '',
  EMBONOR_SERVERLESS_SIGNUP_SERVICE: process.env.GATSBY_EMBONOR_SERVERLESS_SIGNUP_SERVICE,
  GOOGLE_RECAPTCHAV3_SITE_KEY: process.env.GATSBY_GOOGLE_RECAPTCHAV3_SITE_KEY || '',
  ALGOLIA_APP_ID: process.env.GATSBY_ALGOLIA_APP_ID || '',
  ALGOLIA_INDEX_NAME: process.env.GATSBY_ALGOLIA_INDEX_NAME || '',
  ALGOLIA_API_KEY: process.env.GATSBY_ALGOLIA_API_KEY || '',
  MAPS_QUERY_BLACKLIST: process.env.GATSBY_MAPS_QUERY_BLACKLIST || '',
  SANITIZE_REGIONS: process.env.GATSBY_MAPS_SANITIZE_REGIONS || '',
  SKU_LIST_LIMITED_SALE: process.env.GATSBY_SKU_LIST_LIMITED_SALE || '',
  BFF_HOST: process.env.GATSBY_BFF_URL,
  CUSTOMER_BFF_HOST: process.env.GATSBY_CUSTOMER_BFF_HOST,
  FIREBASE_API_KEY: process.env.GATSBY_FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
  GATSBY_FIREBASE_AUTH_EMAIL: process.env.GATSBY_FIREBASE_AUTH_EMAIL || '',
  GATSBY_FIREBASE_AUTH_SECRET: process.env.GATSBY_FIREBASE_AUTH_SECRET || '',
  FIREBASE_DATABASE_URL: process.env.GATSBY_FIREBASE_DATABASE_URL,
  FIREBASE_PROJECT_ID: process.env.GATSBY_FIREBASE_PROJECT_ID,
  FIREBASE_APP_ID: process.env.GATSBY_FIREBASE_APP_ID,
  FIREBASE_CARTS_COLLECTION: process.env.GATSBY_FIREBASE_CARTS_COLLECTION || '',
  COUNTRY: process.env.GATSBY_COUNTRY as Country,
  MIN_GIFT_CARD_CODE_LENGTH: Number(process.env.MIN_GIFT_CARD_CODE_LENGTH ?? 16),
  INCLUDES_BOX_PRODUCT_FORMATS: process.env.GATSBY_INCLUDES_BOX_PRODUCT_FORMATS ?? '',
  JW_PRESALE_SKU: -1,
  FIREBASE_SIMULATOR_PORT: process.env.GATSBY_FIREBASE_SIMULATOR_PORT
    ? Number(process.env.GATSBY_FIREBASE_SIMULATOR_PORT)
    : undefined,
}
